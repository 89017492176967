import { put, takeEvery, call } from 'redux-saga/effects'
import http from '@/http'
import {Actions} from './action'
import ActionTypes from './constants'

function* getAssociateCompany(action){
    try {
        const {payload} = action
        let options = {
            url: '/web/portrait/getAssociateCompany',
            method: 'GET',
            params: {
                ...payload
            }
        }
        let result = yield call(http, {...options})
        yield put(Actions.getAssociateCompanyEnterpriseSuccess(result))
    } catch(err) {
        yield put(Actions.getAssociateCompanyEnterpriseFail())
    }
}

function* watch() {
    yield takeEvery(ActionTypes.GET_ASSOCIATE_COMPANY_ENTERPRISE, getAssociateCompany)
}

const Sagas = [
    watch()
]

export default Sagas