import ActionsType from './constants'

let initialState = {
    searchParams: {},
    data: {},
    loading: false,

    showTipModal: false
};

const reducer = (state = initialState, action) => {
    const { type, payload} = action
    switch (type) {
        case ActionsType.GET_PROFILE_DATA:
            return Object.assign({}, state, {
                ...state, data: {}, loading: true, searchParams: {...payload}
            });
        case ActionsType.GET_PROFILE_DATA_SUCCESS:
            return Object.assign({}, state, {
                ...state, data: payload, loading: false
            });
        case ActionsType.GET_PROFILE_DATA_FAIL:
            return Object.assign({}, state, {
                ...state, data: {}, loading: false
            });
        
        case ActionsType.GET_REPORT:
            return Object.assign({}, state, {
                ...state, data: {}
            });
        case ActionsType.GET_REPORT_SUCCESS:
            return Object.assign({}, state, {
                ...state, data: payload
            });
        case ActionsType.GET_REPORT_FAIL:
            return Object.assign({}, state, {
                ...state, data: {}
            });

        case ActionsType.SHOW_TIP_MODAL:
            return Object.assign({}, state, {
                ...state, showTipModal: payload
            });
        
        default:
            return state;
    }
};

export default reducer