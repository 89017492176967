import { put, takeEvery } from 'redux-saga/effects'
import http from '../../../http'
import {Actions} from './action'
import ActionTypes from './constants'

function* getBill(action){
    try {
        const { payload } = action
        let result = yield http({
            url: '/web/flow/page',
            params: payload ? {...payload} : {pageSize: 10, pageNo: 1}
        })
        result.results.map((item) => {
            item.key = item.id
        })
        result.lastReq = payload
        yield put(Actions.getBillSuccess(result))
    } catch(err) {
        yield put(Actions.getBillFail())
    }
}

function* watch() {
    yield takeEvery(ActionTypes.GET_BILL, getBill)
}

const Sagas = [
    watch()
]

export default Sagas