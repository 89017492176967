import React, { useEffect } from 'react'
import { connect } from 'react-redux';
import { Form, Input, Button} from 'antd';
import { Actions } from './action'
import {Actions as AccountActions} from '../system/account/action'
import logoUrl from '../../assets/login/logo.png'
import viceLogoUrl from '../../assets/login/vice-logo.png'
import coverUrl from '../../assets/login/cover.png'
import bgUrl from '../../assets/login/bg.png'
import './index.css'
import { useHistory } from 'react-router-dom';
const Index = (props) => {
    const { loginLoading, onLogin, onGetAuthInfo, onGetAccountInfo, onGetAuthMenu } = props
    const [form] = Form.useForm();

    useEffect(() => {
        if(localStorage.getItem('idrp-mobile')) {
            form.setFieldsValue({mobile: localStorage.getItem('idrp-mobile')})
        }
    }, [])
    // 登录
    const history = useHistory();
    const RenderLogin = () => {
        const onFinish = (values) => {
            onLogin({ ...values }, () => {
                localStorage.setItem('idrp-mobile', values.mobile)
                onGetAuthInfo(null, authRes => {
                    onGetAccountInfo(null, accountRes => {})
                    onGetAuthMenu(authRes.roleId, authMenu => {
                        history.push("/");
                        if(authMenu.length) {
                            let menuItems = authMenu.filter(item => item.isMenu === 0)
                            if(menuItems.length) {
                                // history.replace(menuItems[0].url)
                            }
                        } else {
                            // history.replace('/noauth')
                        }
                    })
                })
            })
        }
        const onFinishFailed = (errorInfo) => {
            console.log('Failed:', errorInfo);
        }
        return (
            <Form
                form={form}
                name='basic'
                className='login-form'
                initialValues={{
                    remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
            >
                <div className='login-form-header'>登录</div>
                <Form.Item
                    name='mobile'
                    rules={[
                        {
                            required: true,
                            message: '账户不能为空',
                        }
                    ]}
                >
                    <Input className='login-form-input' placeholder='请输入账户' bordered={false} />
                </Form.Item>
                <Form.Item
                    name='password'
                    rules={[
                        {
                            required: true,
                            message: '密码不能为空',
                        }
                    ]}
                >
                    <Input.Password className='login-form-input' placeholder='请输入密码' bordered={false} />
                </Form.Item>
                <Form.Item className='login-form-item'>
                    <Button type='primary' className='login-form-btn' size='large' htmlType='submit' disabled={loginLoading}>{loginLoading? '登陆中...': '登录'}</Button>
                </Form.Item>
            </Form>
        )
    }

    return (
        <div className='login-bg' style={{ backgroundImage: `url(${bgUrl})` }}>
            <div className='login-main'>
                <div className='login-main-header'>
                    <img className='login-main-header-logo' src={logoUrl} alt='logo' />
                    <img className='login-main-header-vicelogo' src={viceLogoUrl} alt='vice-logo' />
                </div>
                <div className='login-main-content'>
                    <img className='login-main-content-image' src={coverUrl} alt='cover' />
                    <RenderLogin />
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        loginLoading: state.login.loginLoading,
        authInfo: state.login.authInfo,
        authMenu: state.login.authMenu
    }
};
const mapDispatchToProps = dispatch => {
    return {
        onLogin: (params, callback) => dispatch(Actions.login(params, callback)),
        onGetAuthInfo: (params, callback) => dispatch(Actions.getAuthInfo(params, callback)),
        onGetAccountInfo: (params, callback) => dispatch(AccountActions.getAccountInfo(params, callback)),
        onGetAuthMenu: (params, callback) => dispatch(Actions.getAuthMenu(params, callback))
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(Index)
