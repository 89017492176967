import ActionsType from './constants'
//折叠/展开左侧菜单栏
export const Actions = {
    getAuthorizationLink(params) {
        return {type: ActionsType.GET_AUTHORIZATIONLINK, payload: params}
    },
    getAuthorizationLinkSuccess(result) {
        return {type: ActionsType.GET_AUTHORIZATIONLINK_SUCCESS, payload: result}
    },
    getAuthorizationLinkFail() {
        return {type: ActionsType.GET_AUTHORIZATIONLINK_FAIL}
    }
}