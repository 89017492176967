import ActionsType from './constants'
//折叠/展开左侧菜单栏
export const Actions = {
    getProfileData(params, callback) {
        return {type: ActionsType.GET_PROFILE_DATA, payload: params, callback: callback}
    },
    getProfileDataSuccess(params) {
        return {type: ActionsType.GET_PROFILE_DATA_SUCCESS, payload: params}
    },
    getProfileDataFail(params) {
        return {type: ActionsType.GET_PROFILE_DATA_FAIL, payload: params}
    },

    getReport(params, callback) {
        return {type: ActionsType.GET_REPORT, payload: params, callback: callback}
    },
    getReportSuccess(params) {
        return {type: ActionsType.GET_REPORT_SUCCESS, payload: params}
    },
    getReportFail(params) {
        return {type: ActionsType.GET_REPORT_FAIL, payload: params}
    },

    showTipModal(params) {
        return {type: ActionsType.SHOW_TIP_MODAL, payload: params}
    },

    getSnapShot(params, callback) {
        return {type: ActionsType.GET_SNAPSHOT_DATA, payload: params, callback: callback}
    },
    getSnapShotSuccess(params) {
        return {type: ActionsType.GET_SNAPSHOT_DATA_SUCCESS, payload: params}
    },
    getSnapShotFail(params) {
        return {type: ActionsType.GET_SNAPSHOT_DATA_FAIL, payload: params}
    },
}