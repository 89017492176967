import ActionsType from './constants'
//折叠/展开左侧菜单栏
export const Actions = {
    // 用户列表
    getUserList(params) {
        return { type: ActionsType.GET_USER_LIST, payload: params }
    },
    getUserListSuccess(result) {
        return { type: ActionsType.GET_USER_LIST_SUCCESS, payload: result }
    },
    getUserListFail() {
        return { type: ActionsType.GET_USER_LIST_FAIL }
    },

    // 单个用户信息
    getUserOne(params, callback) {
        return { type: ActionsType.GET_USER_ONE, payload: params, callback: callback }
    },
    getUserOneSuccess(result) {
        return { type: ActionsType.GET_USER_ONE_SUCCESS, payload: result }
    },
    getUserOneFail() {
        return { type: ActionsType.GET_USER_ONE_FAIL }
    },

    // 新增用户
    addUser(params, callback) {
        return { type: ActionsType.ADD_USER, payload: params, callback: callback }
    },
    addUserSuccess(result) {
        return { type: ActionsType.ADD_USER_SUCCESS, payload: result }
    },
    addUserFail() {
        return { type: ActionsType.ADD_USER_FAIL }
    },

    // 编辑单个用户信息
    editUser(params, callback) {
        return { type: ActionsType.EDIT_USER, payload: params, callback }
    },
    editUserSuccess(result) {
        return { type: ActionsType.EDIT_USER_SUCCESS, payload: result }
    },
    editUserFail() {
        return { type: ActionsType.EDIT_USER_FAIL }
    },

    // 改变用户状态
    changeUserStatus(params, callback) {
        return { type: ActionsType.CHANGE_USER_STATUS, payload: params, callback: callback }
    },
    changeUserStatusSuccess(result) {
        return { type: ActionsType.CHANGE_USER_STATUS_SUCCESS, payload: result }
    },
    changeUserStatusFail() {
        return { type: ActionsType.CHANGE_USER_STATUS_FAIL }
    },

    // 删除用户信息
    deleteUser(params, callback) {
        return { type: ActionsType.DELETE_USER, payload: params, callback }
    },
    deleteUserSuccess(result) {
        return { type: ActionsType.DELETE_USER_SUCCESS, payload: result }
    },
    deleteUserFail() {
        return { type: ActionsType.DELETE_USER_FAIL }
    },

}