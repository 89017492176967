import { put, takeEvery, call, select } from 'redux-saga/effects'
import http from '../../../http'
import { Actions } from './action'
import ActionTypes from './constants'
import { message, Modal } from 'antd'
import NoDataPic from '@/assets/img/search-no-data.png'

import { setSearchHistory, getSearchHistory } from './utils'

// 查询画像记录
function* getEnterpriseSearchList(action) {
    try {
        const { payload } = action
        let options = {
            url: '/web/record/userPageRecord',
            params: payload ? { ...payload, type: 2 } : { pageSize: 10, pageNo: 1, type: 2 }
        }
        let result = yield call(http, { ...options })
        result.lastReq = payload
        yield put(Actions.getEnterPriseSearchListSuccess(result))
    } catch (err) {
        yield put(Actions.getEnterPriseSearchListFail())
    }
}

// 搜索画像
function* sendEnterPriseSearchRequest(action) {
    try {
        const { payload, callback } = action
        let options = {
            url: '/web/portrait/search',
            method: 'POST',
            data: {
                ...payload,
                searchType: 2
            }
        }
        let result = yield call(http, { ...options })
        yield put(Actions.sendEnterPriseSearchRequestSuccess(result))
        yield put(Actions.setIsNeedCheck(false))
        // 设置搜索的历史记录
        if (result?.baseInfo) {
            let account = yield select(state => state.login.authInfo)
            let history = getSearchHistory(account.id) ? getSearchHistory(account.id).split(',') : []
            history.push(payload.searchName)
            history = Array.from(new Set([...history]))
            if (history.length > 10) {
                history = history.slice(0, 10)
            }
            setSearchHistory(account ? account.id : '', history.join(','))
        }
        // statusCode:
        // 0: 申请成功
        // 1: 未授权
        // 2: 余额不足
        // 3: 生成中
        // 4: 已生成
        // 5: 接口有波动
        // 6: 无企业数据
        // 7: 请勿频繁操作
        // 数据状态码为以下时, 才执行回调,回调是跳转到画像页面
        if (callback && (result.statusCode === 0 || result.statusCode === 1 || result.statusCode === 2 || result.statusCode === 3 || result.statusCode === 4 || result.statusCode === 5)) {
            callback(result)
        } else if (result.statusCode === 6) {
            Modal.info({
                width: 800,
                icon: null,
                content: <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <img style={{ width: '270px', height: '190px' }} src={NoDataPic} alt="找不到查询企业或暂无该企业数据" />
                    <div style={{ marginTop: '25px', color: '#666666', fontSize: '18px' }}>找不到查询企业或暂无该企业数据</div>
                    <div style={{ marginTop: '15px', color: '#999999', fontSize: '14px' }}>请重新核实查询企业名称，如有需要请联系平台客服。</div>
                </div>,
                footer: null
            })
        } else if (result.statusCode === 7) {
            message.warn(result.msg)
        }
    } catch (err) {
        yield put(Actions.sendEnterPriseSearchRequestFail())
    }
}

// 判断是否有历史画像
function* getEnterpriseData(action) {
    try {
        const { payload, callback } = action
        let options = {
            url: '/web/portrait/judge',
            method: 'GET',
            params: {
                ...payload,
                searchType: 2
            }
        }
        let result = yield call(http, { ...options })
        if (result !== null) {
            yield put(Actions.getEnterpriseDataSuccess(result))
            yield put(Actions.showEnterPriseDataExistTip(true))
        } else {
            const { searchName } = payload
            yield put(Actions.getEnterpriseDataSuccess(null))
            yield put(Actions.getEnterpriseBaseinfo({ searchName }, callback))
        }
    } catch (err) {
        yield put(Actions.getEnterpriseDataFail())
    }
}

// 获取报告
function* getEnterpriseReport(action) {
    try {
        const { payload, callback } = action
        let options = {
            url: '/web/record/getReport',
            method: 'GET',
            params: { ...payload }
        }
        let result = yield call(http, { ...options })
        // 记录状态等于3，走完整画像的流程
        if (payload.status !== 3) {
            yield put(Actions.sendEnterPriseSearchRequestSuccess({ statusCode: result.statusCode, msg: result.msg, baseInfo: { baseInfo: result.report?.busiInfo?.baseInfo } }))
        } else {
            yield put(Actions.getEnterpriseReportSuccess(result.report))
        }
        if (callback) {
            callback(result)
        }
    } catch (err) {
        yield put(Actions.getEnterpriseReportFail())
    }
}

// 检查配置参数
function* getEnterpriseCheck(action) {
    try {
        const { payload, callback } = action
        let options = {
            url: '/web/portrait/check',
            method: 'GET',
            params: { ...payload, searchType: 2 }
        }
        let result = yield call(http, { ...options })
        yield put(Actions.getEnterpriseCheckSuccess(result))
        if (callback) {
            callback(result)
        }
    } catch (err) {
        yield put(Actions.getEnterpriseCheckFail(err))
    }
}

// 基本信息
function* getEnterpriseBaseinfo(action) {
    try {
        const { payload, callback } = action
        let options = {
            url: '/web/portrait/baseInfo',
            method: 'GET',
            params: { ...payload }
        }
        let result = yield call(http, { ...options })
        if (result !== null) {
            yield put(Actions.sendEnterPriseSearchRequestSuccess({
                statusCode: 0,
                baseInfo: { baseInfo: result.baseInfo }
            }))
            yield put(Actions.getEnterpriseBaseinfoSuccess({ ...payload }))
        } else {
            yield put(Actions.showEnterPriseDataSearchAgainTip(true))
        }
        if (callback) {
            callback(result)
        }
    } catch (err) {
        yield put(Actions.getEnterpriseBaseinfoFail())
    }
}

function* watch() {
    yield takeEvery(ActionTypes.GET_ENTERPRISE_SEARCH_LIST, getEnterpriseSearchList)
    yield takeEvery(ActionTypes.SEND_ENTERPRISE_SEARCH_REQUEST, sendEnterPriseSearchRequest)
    yield takeEvery(ActionTypes.GET_ENTERPRISE_DATA, getEnterpriseData)
    yield takeEvery(ActionTypes.GET_ENTERPRISE_REPORT, getEnterpriseReport)
    yield takeEvery(ActionTypes.GET_ENTERPRISE_CHECK, getEnterpriseCheck)
    yield takeEvery(ActionTypes.GET_ENTERPRISE_BASEINFO, getEnterpriseBaseinfo)
}

const Sagas = [
    watch()
]

export default Sagas