import ActionsType from './constants'

let initialState = {
    lastReq: {},
    data: [],
    loading: false,
    total: 0,
    pageNo: 1,
    pageSize: 10
};

const reducer = (state = initialState, action) => {
    const { type, payload} = action
    switch (type) {
        case ActionsType.GET_RECORD_LIST:
            return Object.assign({}, state, {
                ...state, loading: true, data: [], total: 0, lastReq: {}
            });
        case ActionsType.GET_RECORD_LIST_SUCCESS:
            return Object.assign({}, state, {
                ...state, 
                loading: false, 
                data: payload.results, 
                pageNo: payload.pageNo, 
                pageSize: payload.pageSize, 
                total: payload.totalRecord,
                lastReq: payload.lastReq
            });
        case ActionsType.GET_RECORD_LIST_FAIL:
            return Object.assign({}, state, {
                ...state, loading: false, data: [], total: 0, lastReq: {}
            });
        
        default:
            return state;
    }
};

export default reducer