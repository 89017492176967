import React, { Suspense } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { CacheSwitch, CacheRoute } from 'react-router-cache-route'
import { Spin } from 'antd';
import Login from '@/page/login'
import {store} from '@/store/store'

const flattenMenu = arr =>
  arr.reduce((prev, item) => {
    prev.push(item);
    return prev.concat(
      Array.isArray(item.children) ? flattenMenu(item.children) : []
    );
}, []);

const FallbackPage = () => {
    return <div style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}><Spin /></div>
}

function renderRoutes (routes, isLogin, extraProps = {}, switchProps = {}) {

    const state = store.getState()
    const { login } = state
    const { authMenu } = login
    const authMenuList = flattenMenu([...authMenu])
    const checkAuth = route => {
        if(route.needAuth) {
            if(route.path.includes(':')) {
                let path = route.path.slice(0, route.path.indexOf(':') - 1)
                return authMenuList.find(menu => menu?.url && menu?.url.indexOf(path) > -1) ? true : false
            }
            return authMenuList.find(menu => menu?.url && menu?.url.indexOf(route.path) > -1) ? true : false
        }
        return true
    }
    
    const generateRouteProps = (route, i) => {
        return {
            key: route.key || i,
            path: route.path,
            exact: route.exact,
            strict: route.strict,
            render: props => {
                // 未登录，直接返回登录页面;noAuth不用认证的除外
                if(!isLogin && route.needLogin) {
                    return <Login />
                }
                // 登录状态进入login页面时，会回到首页
                if(route.path === '/login' && isLogin) {
                    return <Redirect to={"/"} />
                }
                if(checkAuth(route)) {
                    return route.render ? (
                        route.render({ ...props, ...extraProps, route: route })
                    ) : (
                        <route.component {...props} {...extraProps} route={route} />
                    )
                } else {
                    return <Redirect to={"/403"} />
                }
            }
        }
    } 

    return routes ? (
        <Suspense fallback={<FallbackPage />}>
            <CacheSwitch {...switchProps}>
                {   routes.map((route, i) => {
                        if(route.keepAlive) {
                            return <CacheRoute {...generateRouteProps(route, i)} />
                        } else {
                            return <Route {...generateRouteProps(route, i)} />
                        }
                    }
                )}
            </CacheSwitch>
        </Suspense>
    ) : null;
}
export default renderRoutes