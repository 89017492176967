import ActionsType from './constants'
//折叠/展开左侧菜单栏
export const Actions = {
    getAssociateCompanyEnterprise(params) {
        return {type: ActionsType.GET_ASSOCIATE_COMPANY_ENTERPRISE, payload: params}
    },
    getAssociateCompanyEnterpriseSuccess(params) {
        return {type: ActionsType.GET_ASSOCIATE_COMPANY_SUCCESS_ENTERPRISE, payload: params}
    },
    getAssociateCompanyEnterpriseFail(params) {
        return {type: ActionsType.GET_ASSOCIATE_COMPANY_FAIL_ENTERPRISE, payload: params}
    }
}