import ActionsType from './constants'
//折叠/展开左侧菜单栏
export const Actions = {
    // 查询画像记录
    getEnterPriseSearchList(params) {
        return { type: ActionsType.GET_ENTERPRISE_SEARCH_LIST, payload: params }
    },
    getEnterPriseSearchListSuccess(result) {
        return { type: ActionsType.GET_ENTERPRISE_SEARCH_LIST_SUCCESS, payload: result }
    },
    getEnterPriseSearchListFail() {
        return { type: ActionsType.GET_ENTERPRISE_SEARCH_LIST_FAIL }
    },
    // 搜索画像
    sendEnterPriseSearchRequest(params, callback) {
        return { type: ActionsType.SEND_ENTERPRISE_SEARCH_REQUEST, payload: params, callback: callback }
    },
    sendEnterPriseSearchRequestSuccess(result) {
        return { type: ActionsType.SEND_ENTERPRISE_SEARCH_REQUEST_SUCCESS, payload: result }
    },
    sendEnterPriseSearchRequestFail() {
        return { type: ActionsType.SEND_ENTERPRISE_SEARCH_REQUEST_FAIL }
    },
    // 判断是否有历史画像
    getEnterpriseData(params, callback) {
        return { type: ActionsType.GET_ENTERPRISE_DATA, payload: params, callback: callback }
    },
    getEnterpriseDataSuccess(params) {
        return { type: ActionsType.GET_ENTERPRISE_DATA_SUCCESS, payload: params }
    },
    getEnterpriseDataFail(params) {
        return { type: ActionsType.GET_ENTERPRISE_DATA_FAIL, payload: params }
    },
    // 获取报告
    getEnterpriseReport(params, callback) {
        return { type: ActionsType.GET_ENTERPRISE_REPORT, payload: params, callback: callback }
    },
    getEnterpriseReportSuccess(params) {
        return { type: ActionsType.GET_ENTERPRISE_REPORT_SUCCESS, payload: params }
    },
    getEnterpriseReportFail(params) {
        return { type: ActionsType.GET_ENTERPRISE_REPORT_FAIL, payload: params }
    },
    // 检查配置参数
    getEnterpriseCheck(params, callback) {
        return { type: ActionsType.GET_ENTERPRISE_CHECK, payload: params, callback: callback }
    },
    getEnterpriseCheckSuccess(params) {
        return { type: ActionsType.GET_ENTERPRISE_CHECK_SUCCESS, payload: params }
    },
    getEnterpriseCheckFail(params) {
        return { type: ActionsType.GET_ENTERPRISE_CHECK_FAIL, payload: params }
    },
    // 基本信息
    getEnterpriseBaseinfo(params, callback) {
        return { type: ActionsType.GET_ENTERPRISE_BASEINFO, payload: params, callback: callback }
    },
    getEnterpriseBaseinfoSuccess(params) {
        return { type: ActionsType.GET_ENTERPRISE_BASEINFO_SUCCESS, payload: params }
    },
    getEnterpriseBaseinfoFail(params) {
        return { type: ActionsType.GET_ENTERPRISE_BASEINFO_FAIL, payload: params }
    },

    // 更新自定义变量
    setIsNeedCheck(params) {
        return { type: ActionsType.SET_ISNEEDCHECK, payload: params }
    },
    showEnterpriseTipModal(params) {
        return { type: ActionsType.SHOW_ENTERPRISE_TIP_MODAL, payload: params }
    },
    showEnterPriseDataExistTip(payload) {
        return { type: ActionsType.SHOW_ENTERPRISE_DATA_EXIST_TIP, payload: payload }
    },
    showEnterPriseDataSearchAgainTip(payload) {
        return { type: ActionsType.SHOW_ENTERPRISE_DATA_SEARCH_AGAIN_TIP, payload: payload }
    },
    resetEnterPriseSearchResult() {
        return { type: ActionsType.RESET_ENTERPRISE_SEARCH_RESULT }
    }
}