import { lazy } from 'react'
import { Redirect } from 'react-router'
import PageLayout  from '@/layout/pageLayout'

export default {
    name: '用户中心',
    exact: false, 
    path: '/system', 
    component: PageLayout,
    needAuth: true,
    routes: [
        { name: '用户中心', exact: true, path: '/system', needAuth: true, render:() => (<Redirect to={"/system/role"}/>) },
        { name: '角色管理', exact: true, path: '/system/role', needAuth: true, component: lazy(() => import('@/page/system/role')), keepAlive: true },
        { name: '用户管理', exact: true, path: '/system/user', needAuth: true, component: lazy(() => import('@/page/system/user')), keepAlive: true },
        { name: '公司信息与授权', exact: true, path: '/system/account', needAuth: true, component: lazy(() => import('@/page/system/account')), keepAlive: true }
    ]
}