import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { message, Spin } from "antd";
import bgUrl from "../../assets/h5/bg.png";
import copyUrl from "../../assets/h5/copy.png";
import loadingUrl from "../../assets/h5/loading.png";
import rmbUrl from "../../assets/h5/rmb.png";
import "./index.css";
import { Actions } from "./action";
import http from "@/http";
import { omit } from "lodash";

import {
  ExclamationCircleOutlined,
  ClockCircleOutlined,
} from "@ant-design/icons";

const Index = (props) => {
  const { history, loading, authorizationLink, onGetLink } = props;

  // 根据链接上的mode参数，判断是否是静默模式，如果是，则不渲染页面，并执行以下操作
  let arr = props.history.location.search.substring(1).split("&");
  let obj = {};
  for (let i of arr) {
    obj[i.split("=")[0]] = i.split("=")[1]; //对数组每项用=分解开，=前为对象属性名，=后为属性值
  }
  if (obj.mode && obj.mode === "slience") {
    http({
      method: "POST",
      url: `/web/jdApi/offLineAuth`,
      data: {
        ...omit(obj, ["mode"]),
        searchType: 4
      },
    }).then((res) => {
      window.location.href = res;
    });
    return (
      <div
        style={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spin></Spin>
      </div>
    );
  }

  const [isNoBalance, setIsNoBalance] = useState(false);
  useEffect(() => {
    if ([1, 2, 3].includes(authorizationLink)) {
      setIsNoBalance(true);
    }
  }, [authorizationLink]);

  const generateLink = async () => {
    // 生成授权链接
    const { search } = props.history.location;
    let arr = search.substring(1).split("&"); //先通过？分解得到？后面的所需字符串，再将其通过&分解开存放在数组里
    let obj = {};
    for (let i of arr) {
      obj[i.split("=")[0]] = i.split("=")[1]; //对数组每项用=分解开，=前为对象属性名，=后为属性值
    }
    onGetLink(obj); // 1:未授权 2.余额不足
  };

  // 复制
  const copy = (text) => {
    return () => {
      let tag = document.createElement("input");
      tag.setAttribute("id", "cp_input");
      tag.value = text;
      document.getElementsByTagName("body")[0].appendChild(tag);
      document.getElementById("cp_input").select();
      document.execCommand("copy");
      document.getElementById("cp_input").remove();
      message.success("复制成功！");
    };
  };

  return (
    <div className="h5-bg" style={{ backgroundImage: `url(${bgUrl})` }}>
      <p className="h5-title">产业数据风险管理平台</p>
      <p className="h5-subtitle">让数据服务产业</p>
      {authorizationLink.length > 1 && (
        <div className="h5-popup">
          <div className="h5-popup-link">{authorizationLink}</div>
          <div className="h5-popup-btn" onClick={copy(authorizationLink)}>
            复制
            <img src={copyUrl} alt="copy" />
          </div>
        </div>
      )}
      {!isNoBalance && (
        <button
          className="h5-btn"
          onClick={() => {
            generateLink();
          }}
        >
          生成授权链接{loading && <img src={loadingUrl} alt="loading" />}
        </button>
      )}
      {/* 余额不足 */}
      {isNoBalance && (
        <>
          {authorizationLink === 1 && (
            <div className="h5-noBalanceTip">
              <ExclamationCircleOutlined
                style={{
                  margin: "20px 0 20px 0",
                  color: "#8c8c8c",
                  fontSize: "40px",
                }}
              />
              <div className="h5-noBalanceTip-mainTit">未授权</div>
              <div className="h5-noBalanceTip-viceTit">
                您账户未授权，如需要查询，请联系客服，客服 热线：400-115-2868
              </div>
            </div>
          )}
          {authorizationLink === 2 && (
            <div className="h5-noBalanceTip">
              <ClockCircleOutlined
                style={{
                  margin: "20px 0 20px 0",
                  color: "#faad14",
                  fontSize: "40px",
                }}
              />
              <div className="h5-noBalanceTip-mainTit">数据生成中</div>
              <div className="h5-noBalanceTip-viceTit">
                数据生成中，过程需要1~2天，请等待
              </div>
            </div>
          )}
          {authorizationLink === 3 && (
            <div className="h5-noBalanceTip">
              <img src={rmbUrl} alt="rmb" />
              <div className="h5-noBalanceTip-mainTit">账户余额不足</div>
              <div className="h5-noBalanceTip-viceTit">
                您账户余额不足，无法查询企业更多信息
                ，如需要查询，请及时充值，客服 热线：400-115-2868
              </div>
            </div>
          )}
          <button
            className="h5-btn"
            onClick={() => {
              setIsNoBalance(false);
            }}
          >
            我已知晓
          </button>
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.h5.loading,
    authorizationLink: state.h5.authorizationLink,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetLink: (params) => dispatch(Actions.getAuthorizationLink(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
