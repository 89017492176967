import { lazy } from 'react'
import { Redirect } from 'react-router'
import PageLayout from '@/layout/pageLayout'

export default {
    name: '全景画像',
    exact: false, 
    path: '/enterprise', 
    component: PageLayout,
    needAuth: true,
    routes: [
        { name: '全景画像', exact: true, path: '/enterprise', needAuth: true, render:() => (<Redirect to={"/enterprise/search/default"}/>) },
        { name: '全景画像搜索', exact: true, path: '/enterprise/search/:name', needAuth: true, component: lazy(() => import('@/page/enterprise/search')), keepAlive: true },
        { name: '查询记录', exact: true, path: '/enterprise/record', needAuth: true, component: lazy(() => import('@/page/enterprise/record')), keepAlive: true },
        { name: '全景画像详情', exact: true, path: '/enterprise/info', needAuth: true, component: lazy(() => import('@/page/enterprise/info')) }
    ]
}
