import ActionsType from './constants'

let initialState = {
    lastReq: {},
    data: [],
    loading: false,
    total: 0,
    pageNo: 1,
    pageSize: 10,

    sendReqLoading: false,

    showDataExistTip: false,

    searchParams: {},
    judgeData: {}
};

const reducer = (state = initialState, action) => {
    const { type, payload} = action
    switch (type) {
        case ActionsType.GET_SNAPSHOT_SEARCH_LIST:
            return Object.assign({}, state, {
                ...state, loading: true, data: [], total: 0, lastReq: {}
            });
        case ActionsType.GET_SNAPSHOT_SEARCH_LIST_SUCCESS:
            return Object.assign({}, state, {
                ...state, 
                loading: false, 
                data: payload.results, 
                pageNo: payload.pageNo, 
                pageSize: payload.pageSize, 
                total: payload.totalRecord,
                lastReq: payload.lastReq
            });
        case ActionsType.GET_SNAPSHOT_SEARCH_LIST_FAIL:
            return Object.assign({}, state, {
                ...state, loading: false, data: [], total: 0, lastReq: {}
            });
    
            case ActionsType.GET_SNAPSHOT_JUDGE:
                return Object.assign({}, state, {
                    ...state, searchParams: {...payload}, sendReqLoading: true
                });
            case ActionsType.GET_SNAPSHOT_JUDGE_SUCCESS:
                return Object.assign({}, state, {
                    ...state, judgeData: payload, sendReqLoading: false
                });
            case ActionsType.GET_SNAPSHOT_JUDGE_FAIL:
                return Object.assign({}, state, {
                    ...state, sendReqLoading: false
                });

        case ActionsType.SEND_SNAPSHOT_SEARCH_REQUEST:
            return Object.assign({}, state, {
                ...state, sendReqLoading: true
            });
        case ActionsType.SEND_SNAPSHOT_SEARCH_REQUEST_SUCCESS:
            return Object.assign({}, state, {
                ...state, 
                sendReqLoading: false,
                statusCode: payload.statusCode, msg: payload.msg, baseInfoPreview: payload.baseInfo?.baseInfo
            });
        case ActionsType.SEND_SNAPSHOT_SEARCH_REQUEST_FAIL:
            return Object.assign({}, state, {
                    ...state, sendReqLoading: false
            });

        case ActionsType.SHOW_DATA_EXIST_TIP:
            return Object.assign({}, state, {
                ...state, showDataExistTip: payload
            });
        
        default:
            return state;
    }
};

export default reducer