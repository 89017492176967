import ActionsType from './constants'

let initialState = {
    lastReq: {},
    data: [],
    loading: false,
    total: 0,
    pageNo: 1,
    pageSize: 10,

    userInfo: {}
};

const reducer = (state = initialState, action) => {
    const {
        type,
        payload
    } = action
    switch (type) {
        // 用户列表
        case ActionsType.GET_USER_LIST:
            return Object.assign({}, state, {
                ...state,
                loading: true,
                data: [],
                total: 0,
                lastReq: {}
            });
        case ActionsType.GET_USER_LIST_SUCCESS:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                data: payload.results,
                pageNo: payload.pageNo,
                pageSize: payload.pageSize,
                total: payload.totalRecord,
                lastReq: payload.lastReq
            });
        case ActionsType.GET_USER_LIST_FAIL:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                data: [],
                total: 0,
                lastReq: {}
            });

            // 单个用户信息
        case ActionsType.GET_USER_ONE:
            return Object.assign({}, state, {
                ...state,
                userInfo: {}
            });
        case ActionsType.GET_USER_ONE_SUCCESS:
            return Object.assign({}, state, {
                ...state,
                userInfo: payload
            });
        case ActionsType.GET_USER_ONE_FAIL:
            return Object.assign({}, state, {
                ...state,
                userInfo: {}
            });
            // 冻结或者恢复账号
        case ActionsType.CHANGE_USER_STATUS:
            return Object.assign({}, state, {
                ...state,
                changeStatusLoading: true
            });
        case ActionsType.CHANGE_USER_STATUS_SUCCESS:
            return Object.assign({}, state, {
                ...state,
                changeStatusLoading: false
            });
        case ActionsType.CHANGE_USER_STATUS_FAIL:
            return Object.assign({}, state, {
                ...state,
                changeStatusLoading: false
            });
            
            // 编辑用户信息
        case ActionsType.EDIT_USER:
            return Object.assign({}, state, {
                ...state
            });
        case ActionsType.EDIT_USER_SUCCESS:
            return Object.assign({}, state, {
                ...state,
            });
        case ActionsType.EDIT_USER_FAIL:
            return Object.assign({}, state, {
                ...state,
            });

            // 删除用户
        case ActionsType.DELETE_USER:
            return Object.assign({}, state, {
                ...state
            });
        case ActionsType.DELETE_USER_SUCCESS:
            return Object.assign({}, state, {
                ...state,
            });
        case ActionsType.DELETE_USER_FAIL:
            return Object.assign({}, state, {
                ...state,
            });

        default:
            return state;
    }
};

export default reducer