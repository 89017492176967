import ActionsType from './constants'

let initialState = {
    loading: false,
    authorizationLink: '',
};

const reducer = (state = initialState, action) => {
    const {
        type,
        payload
    } = action
    switch (type) {
        case ActionsType.GET_AUTHORIZATIONLINK:
            return Object.assign({}, state, {
                ...state,
                loading: true,
                authorizationLink: ''
            });
        case ActionsType.GET_AUTHORIZATIONLINK_SUCCESS:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                authorizationLink: payload
            });
        case ActionsType.GET_AUTHORIZATIONLINK_FAIL:
            return Object.assign({}, state, {
                ...state,
                loading: false
            });
        default:
            return state;

    }
};

export default reducer