import { put, takeEvery, call, select } from 'redux-saga/effects'
import http from '../../../http'
import {Actions} from './action'
import ActionTypes from './constants'
import {message, Modal} from 'antd'
import NoDataPic from '@/assets/img/search-no-data.png'

import { setSearchHistory, getSearchHistory } from './utils'

function* getSearchList(action){
    try {
        const { payload } = action
        let options = {
            url: '/web/record/userPageRecord',
            params: payload ? {...payload, type: 0} : {pageSize: 10, pageNo: 1, type: 0}
        }
        let result = yield call(http, {...options})
        result.lastReq = payload
        yield put(Actions.getSearchListSuccess(result))
    } catch(err) {
        yield put(Actions.getSearchListFail())
    }
}

function* sendSearchReq(action){
    try {
        const {payload, callback} = action
        let options = {
            url: '/web/portrait/search',
            method: 'POST',
            data: {
                ...payload,
                searchType: 0
            }
        }
        let result = yield call(http, {...options})
        yield put(Actions.sendSearchRequestSuccess(result))
        // 设置搜索的历史记录
        if(result?.baseInfo) {
            let account = yield select(state => state.login.authInfo)
            let history = getSearchHistory(account.id) ? getSearchHistory(account.id).split(',') : []
            console.log(history)
            history.push(payload.searchName)
            history = Array.from(new Set([...history]))
            if(history.length > 10) {
                history = history.slice(0, 10)
            }
            setSearchHistory(account ? account.id : '', history.join(','))
        }
        // statusCode:
        // 0: 申请成功
        // 1: 未授权
        // 2: 余额不足
        // 3: 生成中
        // 4: 已生成
        // 5: 接口有波动
        // 6: 无企业数据
        // 7: 请勿频繁操作
        // 数据状态码为以下时, 才执行回调,回调是跳转到画像页面
        if(callback && (result.statusCode === 0 || result.statusCode === 1 || result.statusCode === 2 || result.statusCode === 3 || result.statusCode === 4 || result.statusCode === 5)) {
            callback()
        }else if(result.statusCode === 6) {
            Modal.info({
                width: 800,
                icon: null,
                content: <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <img style={{width: '270px', height: '190px'}} src={NoDataPic} alt="找不到查询企业或暂无该企业数据" />
                    <div style={{marginTop: '25px', color: '#666666', fontSize: '18px'}}>找不到查询企业或暂无该企业数据</div>
                    <div style={{marginTop: '15px', color: '#999999', fontSize: '14px'}}>请重新核实查询企业名称，如有需要请联系平台客服。</div>
                </div>,
                footer: null
            })
        } else if(result.statusCode === 7) {
            message.warn(result.msg)
        } 
    } catch(err) {
        yield put(Actions.sendSearchRequestFail())
    }
}

function* watch() {
    yield takeEvery(ActionTypes.GET_SEARCH_LIST, getSearchList)
    yield takeEvery(ActionTypes.SEND_SEARCH_REQUEST, sendSearchReq)
}

const Sagas = [
    watch()
]

export default Sagas