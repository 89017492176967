import ActionsType from './constants'

let initialState = {
    lastReq: {},
    data: [],
    loading: false,
    total: 0,
    pageNo: 1,
    pageSize: 10,

    sendReqLoading: false,

    statusCode: -1,
    msg: '',
    baseInfoPreview: {},
    
    checkStatus: -1,
    

    searchParams: {},
    enterpriseData: {},
    enterpriseLoading: false,
    
    
    showDataExistTip: false,
    showDataSearchAgainTip: false, // 确认授权后的再次提示
    showTipModal: false,
    isNeedCheck: true, // 是否需要检查
};

const reducer = (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
        // 查询画像记录
        case ActionsType.GET_ENTERPRISE_SEARCH_LIST:
            return Object.assign({}, state, {
                ...state, loading: true, data: [], total: 0, lastReq: {}
            });
        case ActionsType.GET_ENTERPRISE_SEARCH_LIST_SUCCESS:
            return Object.assign({}, state, {
                ...state,
                loading: false,
                data: payload.results,
                pageNo: payload.pageNo,
                pageSize: payload.pageSize,
                total: payload.totalRecord,
                lastReq: payload.lastReq
            });
        case ActionsType.GET_ENTERPRISE_SEARCH_LIST_FAIL:
            return Object.assign({}, state, {
                ...state, loading: false, data: [], total: 0, lastReq: {}
            });
        // 搜索画像
        case ActionsType.SEND_ENTERPRISE_SEARCH_REQUEST:
            return Object.assign({}, state, {
                ...state, sendReqLoading: true
            });
        case ActionsType.SEND_ENTERPRISE_SEARCH_REQUEST_SUCCESS:
            return Object.assign({}, state, {
                ...state,
                sendReqLoading: false,
                statusCode: payload.statusCode,
                msg: payload.msg,
                baseInfoPreview: payload.baseInfo?.baseInfo
            });
        case ActionsType.SEND_ENTERPRISE_SEARCH_REQUEST_FAIL:
            return Object.assign({}, state, {
                ...state, sendReqLoading: false
            });
        // 判断是否有历史画像
        case ActionsType.GET_ENTERPRISE_DATA:
            return Object.assign({}, state, {
                ...state, enterpriseData: {}, enterpriseLoading: true, searchParams: { ...payload }, isNeedCheck: true
            });
        case ActionsType.GET_ENTERPRISE_DATA_SUCCESS:
            return Object.assign({}, state, {
                ...state, enterpriseData: payload, enterpriseLoading: false
            });
        case ActionsType.GET_ENTERPRISE_DATA_FAIL:
            return Object.assign({}, state, {
                ...state, enterpriseData: {}, enterpriseLoading: false
            });
        // 获取报告
        case ActionsType.GET_ENTERPRISE_REPORT:
            return Object.assign({}, state, {
                ...state, enterpriseData: {}, isNeedCheck: false
            });
        case ActionsType.GET_ENTERPRISE_REPORT_SUCCESS:
            return Object.assign({}, state, {
                ...state, enterpriseData: payload
            });
        case ActionsType.GET_ENTERPRISE_REPORT_FAIL:
            return Object.assign({}, state, {
                ...state, enterpriseData: {}
            });
        // 检查配置参数
        case ActionsType.GET_ENTERPRISE_CHECK:
            return Object.assign({}, state, {
                ...state, checkStatus: -1
            });
        case ActionsType.GET_ENTERPRISE_CHECK_SUCCESS:
            return Object.assign({}, state, {
                ...state, checkStatus: payload
            });
        case ActionsType.GET_ENTERPRISE_CHECK_FAIL:
            return Object.assign({}, state, {
                ...state, checkStatus: -1
            });
        // 基本信息
        case ActionsType.GET_ENTERPRISE_BASEINFO:
            return Object.assign({}, state, {
                ...state, baseInfoLastReq: {}
            });
        case ActionsType.GET_ENTERPRISE_BASEINFO_SUCCESS:
            return Object.assign({}, state, {
                ...state, baseInfoLastReq: payload
            });
        case ActionsType.GET_ENTERPRISE_BASEINFO_FAIL:
            return Object.assign({}, state, {
                ...state, baseInfoLastReq: {}
            });

        // 更新自定义变量
        case ActionsType.SET_ISNEEDCHECK:
            return Object.assign({}, state, {
                ...state, isNeedCheck: payload
            });
        case ActionsType.SHOW_ENTERPRISE_TIP_MODAL:
            return Object.assign({}, state, {
                ...state, showTipModal: payload
            });
        case ActionsType.SHOW_ENTERPRISE_DATA_EXIST_TIP:
            return Object.assign({}, state, {
                ...state, showDataExistTip: payload
            });
        case ActionsType.SHOW_ENTERPRISE_DATA_SEARCH_AGAIN_TIP:
            return Object.assign({}, state, {
                ...state, showDataSearchAgainTip: payload
            });
        case ActionsType.RESET_ENTERPRISE_SEARCH_RESULT:
            return Object.assign({}, state, {
                ...state,
                statusCode: -1,
                msg: '',
                baseInfoPreview: {}
            });

        default:
            return state;
    }
};

export default reducer