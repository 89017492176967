import ActionsType from './constants'

let initialState = {
    lastReq: {},
    data: [],
    loading: false,
    total: 0,
    pageNo: 1,
    pageSize: 10,

    roleInfo: {},

    menu: [],

    roleMenu: []
};

const reducer = (state = initialState, action) => {
    const { type, payload} = action
    switch (type) {
        case ActionsType.GET_ROLE_LIST:
            return Object.assign({}, state, {
                ...state, loading: true, data: [], total: 0, lastReq: {}
            });
        case ActionsType.GET_ROLE_LIST_SUCCESS:
            return Object.assign({}, state, {
                ...state, 
                loading: false, 
                data: payload.results, 
                pageNo: payload.pageNo, 
                pageSize: payload.pageSize, 
                total: payload.totalRecord,
                lastReq: payload.lastReq
            });
        case ActionsType.GET_ROLE_LIST_FAIL:
            return Object.assign({}, state, {
                ...state, loading: false, data: [], total: 0, lastReq: {}
            });
        
        case ActionsType.GET_ROLE:
            return Object.assign({}, state, {
                ...state, roleInfo: {}
            });
        case ActionsType.GET_ROLE_SUCCESS:
            return Object.assign({}, state, {
                ...state, 
                roleInfo: payload
            });
        case ActionsType.GET_ROLE_FAIL:
            return Object.assign({}, state, {
                ...state, roleInfo: {}
            });

        case ActionsType.GET_MENU:
            return Object.assign({}, state, {
                ...state, menu: []
            });
        case ActionsType.GET_MENU_SUCCESS:
            return Object.assign({}, state, {
                ...state, 
                menu: payload
            });
        case ActionsType.GET_MENU_FAIL:
            return Object.assign({}, state, {
                ...state, menu: []
            });

        case ActionsType.GET_ROLE_MENU:
            return Object.assign({}, state, {
                ...state, roleMenu: []
            });
        case ActionsType.GET_ROLE_MENU_SUCCESS:
            return Object.assign({}, state, {
                ...state, 
                roleMenu: payload
            });
        case ActionsType.GET_ROLE_MENU_FAIL:
            return Object.assign({}, state, {
                ...state, roleMenu: []
            });

        default:
            return state;
    }
};

export default reducer