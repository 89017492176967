import ActionsType from './constants'
//折叠/展开左侧菜单栏
export const Actions = {
    getCharge(params) {
        return {type: ActionsType.GET_CHARGE, payload: params}
    },
    getChargeSuccess(result) {
        return {type: ActionsType.GET_CHARGE_SUCCESS, payload: result}
    },
    getChargeFail() {
        return {type: ActionsType.GET_CHARGE_FAIL}
    }
}