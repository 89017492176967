import ActionsType from './constants'
//折叠/展开左侧菜单栏
export const Actions = {
    getSnapshotSearchList(params) {
        return {type: ActionsType.GET_SNAPSHOT_SEARCH_LIST, payload: params}
    },
    getSnapshotSearchListSuccess(result) {
        return {type: ActionsType.GET_SNAPSHOT_SEARCH_LIST_SUCCESS, payload: result}
    },
    getSnapshotSearchListFail() {
        return {type: ActionsType.GET_SNAPSHOT_SEARCH_LIST_FAIL}
    },

    getSnapshotJudge(params, callback) {
        return {type: ActionsType.GET_SNAPSHOT_JUDGE, payload: params, callback: callback}
    },
    getSnapshotJudgeSuccess(result) {
        return {type: ActionsType.GET_SNAPSHOT_JUDGE_SUCCESS, payload: result}
    },
    getSnapshotJudgeFail() {
        return {type: ActionsType.GET_SNAPSHOT_JUDGE_FAIL}
    },

    sendSnapshotSearchRequest(params, callback) {
        return {type: ActionsType.SEND_SNAPSHOT_SEARCH_REQUEST, payload: params, callback: callback}
    },
    sendSnapshotSearchRequestSuccess(result) {
        return {type: ActionsType.SEND_SNAPSHOT_SEARCH_REQUEST_SUCCESS, payload: result}
    },
    sendSnapshotSearchRequestFail() {
        return {type: ActionsType.SEND_SNAPSHOT_SEARCH_REQUEST_FAIL}
    },

    showDataExistTip(payload) {
        return{type: ActionsType.SHOW_DATA_EXIST_TIP, payload: payload}
    }
}