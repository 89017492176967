import ActionsType from './constants'

let initialState = {
    account: {}
};

const reducer = (state = initialState, action) => {
    const { type, payload} = action
    switch (type) {
        case ActionsType.GET_ACCOUNT_INFO:
            return Object.assign({}, state, {
                ...state, account: {}
            });
        case ActionsType.GET_ACCOUNT_INFO_SUCCESS:
            return Object.assign({}, state, {
                ...state, 
                account: payload
            });
        case ActionsType.GET_ACCOUNT_INFO_FAIL:
            return Object.assign({}, state, {
                ...state, account: {}
            });
            
        default:
            return state;
    }
};

export default reducer