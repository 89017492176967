import { put, takeEvery } from 'redux-saga/effects'
import http from '../../../http'
import { Actions } from './action'
import ActionTypes from './constants'

// 查询画像记录
function* getEnterpriseRecordList(action) {
    try {
        const { payload } = action
        let result = yield http({
            url: '/web/record/pageRecord',
            params: { ...payload, type: 2, platform: 1 }
        })
        result.results.map((item) => {
            item.key = item.id
        })
        result.lastReq = payload
        yield put(Actions.getEnterpriseRecordListSuccess(result))
    } catch (err) {
        yield put(Actions.getEnterpriseRecordListFail())
    }
}

// 重新获取授权链接
function* getEnterpriseRecordNewlink(action) {
    try {
        const { payload, callback } = action
        let result = yield http({
            url: '/web/record/newLink',
            params: { ...payload }
        })
        yield put(Actions.getEnterpriseRecordNewlinkSuccess(result))
        if (callback) {
            callback(result)
        }
    } catch (err) {
        yield put(Actions.getEnterpriseRecordNewlinkFail())
    }
}

function* watch() {
    yield takeEvery(ActionTypes.GET_ENTERPRISE_RECORD_LIST, getEnterpriseRecordList)
    yield takeEvery(ActionTypes.GET_ENTERPRISE_RECORD_NEWLINK, getEnterpriseRecordNewlink)
}

const Sagas = [
    watch()
]

export default Sagas