import { put, takeEvery } from 'redux-saga/effects'
import http from '../../../http'
import {Actions} from './action'
import ActionTypes from './constants'

function* getAccountInfo(action){
    try {
        const { payload, callback } = action
        let result = yield http({
            method: 'GET',
            url: '/web/company/getCompanyAndAuth',
            data: {...payload}
        })
        yield put(Actions.getAccountInfoSuccess(result))
        if(callback) {
            callback(result)
        }
    } catch(err) {
        yield put(Actions.getAccountInfoFail())
    }
}

function* editCompanyInfo(action){
    try {
        const { payload, callback } = action
        yield http({
            method: 'POST',
            url: '/web/company/editCompany',
            data: {...payload}
        })
        if(callback) {
            callback()
        }
    } catch(err) {
        console.error(err)
    }
}

function* watch() {
    yield takeEvery(ActionTypes.GET_ACCOUNT_INFO, getAccountInfo)
    yield takeEvery(ActionTypes.EDIT_COMPANY_INFO, editCompanyInfo)
}

const Sagas = [
    watch()
]

export default Sagas