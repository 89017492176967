import ActionsType from './constants'

let initialState = {
    loginLoading: false,
    isLogin: false,
    authInfo: {},
    authMenu: [],
    breadcrumb: []
};

const reducer = (state = initialState, action) => {
    const { type, payload} = action
    switch (type) {
        case ActionsType.LOG_IN:
            return Object.assign({}, state, {
                ...state, loginLoading: true
            });
        case ActionsType.LOG_IN_SUCCESS:
            return Object.assign({}, state, {
                ...state, isLogin: true
            });
        case ActionsType.LOG_IN_FAIL:
            return Object.assign({}, state, {
                ...state, loginLoading: false
            });

        case ActionsType.LOG_OUT:
            return Object.assign({}, state, {
                ...state
            });
        case ActionsType.LOG_OUT_SUCCESS:
            return Object.assign({}, state, {
                ...state, isLogin: false
            });
        case ActionsType.LOG_OUT_FAIL:
            return Object.assign({}, state, {
                ...state
            });

        case ActionsType.GET_AUTH_INFO:
            return Object.assign({}, state, {
                ...state
            });
        case ActionsType.GET_AUTH_INFO_SUCCESS:
            return Object.assign({}, state, {
                ...state, authInfo: payload
            });
        case ActionsType.GET_AUTH_INFO_FAIL:
            return Object.assign({}, state, {
                ...state, loginLoading: false
            });

        case ActionsType.GET_AUTH_MENU:
            return Object.assign({}, state, {
                ...state, authMenu: []
            });
        case ActionsType.GET_AUTH_MENU_SUCCESS:
            return Object.assign({}, state, {
                ...state, authMenu: payload, loginLoading: false
            });
        case ActionsType.GET_AUTH_MENU_FAIL:
            return Object.assign({}, state, {
                ...state, authMenu: [], loginLoading: false
            });
        case ActionsType.SET_BREADCRUMB:
            return Object.assign({}, state, {
                ...state, breadcrumb: payload
            });
        default:
            return state;
    }
};

export default reducer