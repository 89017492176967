import { put, takeEvery, call } from 'redux-saga/effects'
import http from '../../../http'
import {Actions} from './action'
import {Actions as SearchActions} from '../search/action'
import ActionTypes from './constants'

function* getProfileData(action){
    try {
        const {payload, callback} = action
        let options = {
            url: '/web/portrait/judge',
            method: 'GET',
            params: {
                ...payload,
                searchType: 0
            }
        }
        let result = yield call(http, {...options})
        if(result !== null) {
            yield put(Actions.getProfileDataSuccess(result))
            yield put(SearchActions.showDataExistTip(true))
        } else {
            yield put(Actions.getProfileDataSuccess(null))
            yield put(SearchActions.sendSearchRequest({...payload}, callback))
        }
    } catch(err) {
        yield put(Actions.getProfileDataFail())
    }
}

function* getReport(action){
    try {
        const {payload, callback} = action
        let options = {
            url: '/web/record/getReport',
            method: 'GET',
            params: {
                ...payload
            }
        }
        let result = yield call(http, {...options})
        // 记录状态等于3，走完整画像的流程
        if(payload.status !== 3) {
            yield put(SearchActions.sendSearchRequestSuccess({statusCode: result.statusCode, msg: result.msg, baseInfo: {baseInfo: result.report?.busiInfo?.baseInfo}}))
        } else {
            yield put(Actions.getReportSuccess(result.report))
        }
        if(callback) {
            callback()
        }
        
    } catch(err) {
        yield put(Actions.getReportFail())
    }
}

function* watch() {
    yield takeEvery(ActionTypes.GET_PROFILE_DATA, getProfileData)
    yield takeEvery(ActionTypes.GET_REPORT, getReport)
}

const Sagas = [
    watch()
]

export default Sagas