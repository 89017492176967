import ActionsType from './constants'
//折叠/展开左侧菜单栏
export const Actions = {
    // 查询画像记录
    getEnterpriseRecordList(params) {
        return { type: ActionsType.GET_ENTERPRISE_RECORD_LIST, payload: params }
    },
    getEnterpriseRecordListSuccess(result) {
        return { type: ActionsType.GET_ENTERPRISE_RECORD_LIST_SUCCESS, payload: result }
    },
    getEnterpriseRecordListFail() {
        return { type: ActionsType.GET_ENTERPRISE_RECORD_LIST_FAIL }
    },
    // 重新获取授权链接
    getEnterpriseRecordNewlink(params, callback) {
        return { type: ActionsType.GET_ENTERPRISE_RECORD_NEWLINK, payload: params, callback: callback }
    },
    getEnterpriseRecordNewlinkSuccess(result) {
        return { type: ActionsType.GET_ENTERPRISE_RECORD_NEWLINK_SUCCESS, payload: result }
    },
    getEnterpriseRecordNewlinkFail() {
        return { type: ActionsType.GET_ENTERPRISE_RECORD_NEWLINK_FAIL }
    }
}