const ActionsType =  {
    GET_ROLE_LIST: 'GET_ROLE_LIST',
    GET_ROLE_LIST_SUCCESS: 'GET_ROLE_LIST_SUCCESS',
    GET_ROLE_LIST_FAIL: 'GET_ROLE_LIST_FAIL',

    GET_ROLE: 'GET_ROLE',
    GET_ROLE_SUCCESS: 'GET_ROLE_SUCCESS',
    GET_ROLE_FAIL: 'GET_ROLE_FAIL',

    ADD_ROLE: 'ADD_ROLE',
    ADD_ROLE_SUCCESS: 'ADD_ROLE_SUCCESS',
    ADD_ROLE_FAIL: 'ADD_ROLE_FAIL',

    DEL_ROLE: 'DEL_ROLE',
    DEL_ROLE_SUCCESS: 'DEL_ROLE_SUCCESS',
    DEL_ROLE_FAIL: 'DEL_ROLE_FAIL', 

    GET_ROLE_MENU: 'GET_ROLE_MENU',
    GET_ROLE_MENU_SUCCESS: 'GET_ROLE_MENU_SUCCESS',
    GET_ROLE_MENU_FAIL: 'GET_ROLE_MENU_FAIL',

    GET_MENU: 'GET_MENU',
    GET_MENU_SUCCESS: 'GET_MENU_SUCCESS',
    GET_MENU_FAIL: 'GET_MENU_FAIL'
}
export default ActionsType