import './App.less'
import { useEffect } from 'react'
import { connect } from 'react-redux';
import { HashRouter as Router } from 'react-router-dom'
import routes from './route'
import renderRoutes from './route/renderRoutes'
import { Actions } from '@/page/login/action'
function App(props) {
  const { isLogin } = props
  const { onLoginSuccess, onGetAuthInfoSuccess, onGetAuthMenuSuccess } = props
  useEffect(() => {
    // 检查是否登录
    const checkLogin = () => {
      const isLogin = sessionStorage.getItem('isLogin')
      const authInfo = JSON.parse(sessionStorage.getItem('authInfo'))
      const authMenu = JSON.parse(sessionStorage.getItem('authMenu'))
      if(isLogin && authInfo && authMenu) {
        onLoginSuccess(isLogin)
        onGetAuthInfoSuccess(authInfo)
        onGetAuthMenuSuccess(authMenu)
        return true
      }
    }
    checkLogin()
  }, [])

  return (
    <div className="App">
      <Router>
          {renderRoutes(routes, isLogin)}
      </Router>
    </div>
  );
}
const mapStateToProps = state => {
  return {
    isLogin: state.login.isLogin
  }
}
const mapDispatchToProps = dispatch => {
  return {
      onLoginSuccess:params => dispatch(Actions.loginSuccess(params)),
      onGetAuthInfoSuccess: params => dispatch(Actions.getAuthInfoSuccess(params)),
      // onGetAccountInfoSuccess: params => dispatch(AccountActions.getAccountInfo(params)),
      onGetAuthMenuSuccess: params => dispatch(Actions.getAuthMenuSuccess(params))
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(App);
