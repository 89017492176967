import { put, takeEvery, takeLatest } from 'redux-saga/effects'
import http from '../../../http'
import {Actions} from './action'
import ActionTypes from './constants'

function* getRoleList(action){
    try {
        const { payload } = action
        let result = yield http({
            method: 'GET',
            url: '/web/role/pageRole',
            params: payload ? {...payload} : {pageSize: 10, pageNo: 1}
        })
        result.results.map((item) => {
            item.key = item.id
        })
        result.lastReq = payload
        yield put(Actions.getRoleListSuccess(result))
    } catch(err) {
        yield put(Actions.getRoleListFail())
    }
}

function* getRole(action){
    try {
        const { payload, callback } = action
        let result = yield http({
            method: 'GET',
            url: '/web/role/getRole',
            params: {id: payload}
        })
        yield put(Actions.getRoleSuccess(result))
        if(callback) {
            callback()
        }
    } catch(err) {
        yield put(Actions.getRoleFail())
    }
}

function* addRole(action){
    try {
        const { payload, callback } = action
        let result = yield http({
            method: 'POST',
            url: payload.id ? '/web/role/editRole' : '/web/role/addRole',
            data: {...payload, oprates: payload.oprates.map(item => {
                if(item.value) {
                    return item.value
                } else {
                    return item
                }
            }).join(',')}
        })
        yield put(Actions.addRoleSuccess(result))
        if(callback) {
            callback()
        }
    } catch(err) {
        yield put(Actions.addRoleFail())
    }
}

function* delRole(action){
    try {
        const { payload, callback } = action
        let result = yield http({
            method: 'POST',
            url: '/web/role/delRole?roleId=' + payload
        })
        yield put(Actions.delRoleSuccess(result))
        if(callback) {
            callback()
        }
    } catch(err) {
        yield put(Actions.delRoleFail())
    }
}

function* getRoleMenu(action){
    try {
        const { payload, callback } = action
        let result = yield http({
            method: 'POST',
            url: '/web/role/getRoleOprate?roleId=' + payload
        })
        yield put(Actions.getRoleMenuSuccess(result.map(item => item.id)))
        if(callback) {
            callback()
        }
    } catch(err) {
        yield put(Actions.getRoleMenuFail())
    }
}

function* getMenu(action){
    try {
        let result = yield http({
            method: 'POST',
            url: '/web/role/getAllOprate'
        })
        yield put(Actions.getMenuSuccess(result))
    } catch(err) {
        yield put(Actions.getMenuFail())
    }
}

function* watch() {
    yield takeEvery(ActionTypes.GET_ROLE_LIST, getRoleList)
    yield takeEvery(ActionTypes.GET_ROLE, getRole)
    yield takeEvery(ActionTypes.ADD_ROLE, addRole)
    yield takeEvery(ActionTypes.DEL_ROLE, delRole)
    yield takeLatest(ActionTypes.GET_ROLE_MENU, getRoleMenu)
    yield takeLatest(ActionTypes.GET_MENU, getMenu)
}

const Sagas = [
    watch()
]

export default Sagas