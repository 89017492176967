import {
    put,
    takeEvery,
} from 'redux-saga/effects'
import http from '../../../http'
import {
    Actions
} from './action'
import ActionTypes from './constants'

function* getUserList(action) {
    try {
        const {
            payload
        } = action
        let result = yield http({
            url: '/web/staff/pageStaff',
            params: payload ? {
                ...payload
            } : {
                pageSize: 10,
                pageNo: 1
            }
        })
        result.results.map((item) => {
            item.key = item.id
        })
        result.lastReq = payload
        yield put(Actions.getUserListSuccess(result))
    } catch (err) {
        yield put(Actions.getUserListFail())
    }
}

function* addUser(action) {
    try {
        const {
            payload,
            callback
        } = action
        console.log('add', payload)
        let result = yield http({
            method: 'POST',
            url: '/web/staff/addStaff',
            data: {
                ...payload,
                oldPassword: payload.password
            }
        })
        if (result) {
            yield put(Actions.addUserSuccess(result))
            if (callback) {
                callback()
            }
        }
    } catch (err) {
        yield put(Actions.addUserFail())
    }
}

function* editUser(action) {
    try {
        const {
            payload,
            callback
        } = action
        let result = yield http({
            method: 'POST',
            url: '/web/staff/editStaff',
            data: {
                ...payload,
                oldPassword: payload.password
            }
        })
        yield put(Actions.editUserSuccess(result))
        if (callback) {
            callback()
        }
    } catch (err) {
        yield put(Actions.editUserFail())
    }
}

function* getUser(action) {
    try {
        const {
            payload,
            callback
        } = action
        let result = yield http({
            method: 'GET',
            url: `/web/staff/getStaff?id=${payload}`
        })
        let temp = {
            ...result,
            staffEmail: result.email,
            staffName: result.name,
            confirm: result.password,
        }
        yield put(Actions.getUserOneSuccess(temp))
        if (callback) {
            callback()
        }
    } catch (err) {
        yield put(Actions.getUserOneFail())
    }
}

function* changeUserStatus(action) {
    try {
        const {
            payload,
            callback
        } = action
        let result = yield http({
            method: 'POST',
            url: `/web/staff/enableStaff?staffId=${payload.staffId}&status=${payload.status}`,
        })
        yield put(Actions.changeUserStatusSuccess(result))
        if (result && callback) {
            yield callback()
        }
    } catch (err) {
        yield put(Actions.changeUserStatusFail())
    }
}

function* deleteUser(action) {
    try {
        const {
            payload,
            callback
        } = action
        let result = yield http({
            method: 'POST',
            url: `/web/staff/delStaff?staffId=${payload.staffId}`,
            // data: {...payload}
        })
        yield put(Actions.deleteUserSuccess(result))
        if (result && callback) {
            yield callback()
        }
    } catch (err) {
        yield put(Actions.deleteUserFail())
    }
}

function* watch() {
    yield takeEvery(ActionTypes.GET_USER_LIST, getUserList)
    yield takeEvery(ActionTypes.ADD_USER, addUser)
    yield takeEvery(ActionTypes.EDIT_USER, editUser)
    yield takeEvery(ActionTypes.GET_USER_ONE, getUser)
    yield takeEvery(ActionTypes.CHANGE_USER_STATUS, changeUserStatus)
    yield takeEvery(ActionTypes.DELETE_USER, deleteUser)
}

const Sagas = [
    watch()
]

export default Sagas