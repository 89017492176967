import { connect } from 'react-redux'
import renderRoutes from '@/route/renderRoutes';
const PageLayout = (props) => {
    const { route, isLogin } = props
    return (
        <>
            {renderRoutes(route.routes, isLogin)}
        </>
    )
}
const mapStateToProps = state => {
    return {
        isLogin: state.login.isLogin
    }
}
export default connect(mapStateToProps)(PageLayout)