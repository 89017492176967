const ActionsType =  {
    GET_SNAPSHOT_SEARCH_LIST: 'GET_SNAPSHOT_SEARCH_LIST',
    GET_SNAPSHOT_SEARCH_LIST_SUCCESS: 'GET_SNAPSHOT_SEARCH_LIST_SUCCESS',
    GET_SNAPSHOT_SEARCH_LIST_FAIL: 'GET_SNAPSHOT_SEARCH_LIST_FAIL',

    GET_SNAPSHOT_JUDGE: 'GET_SNAPSHOT_JUDGE',
    GET_SNAPSHOT_JUDGE_SUCCESS: 'GET_SNAPSHOT_JUDGE_SUCCESS',
    GET_SNAPSHOT_JUDGE_FAIL: 'GET_SNAPSHOT_JUDGE_FAIL',

    SEND_SNAPSHOT_SEARCH_REQUEST: 'SEND_SNAPSHOT_SEARCH_REQUEST',
    SEND_SNAPSHOT_SEARCH_REQUEST_SUCCESS: 'SEND_SNAPSHOT_SEARCH_REQUEST_SUCCESS',
    SEND_SNAPSHOT_SEARCH_REQUEST_FAIL: 'SEND_SNAPSHOT_SEARCH_REQUEST_FAIL',

    SHOW_DATA_EXIST_TIP:  'SHOW_DATA_EXIST_TIP'
}
export default ActionsType