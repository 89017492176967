const ActionsType =  {
    LOG_IN: 'LOG_IN',
    LOG_IN_SUCCESS: 'LOG_IN_SUCCESS',
    LOG_IN_FAIL: 'LOG_IN_FAIL',

    LOG_OUT: 'LOG_OUT',
    LOG_OUT_SUCCESS: 'LOG_OUT_SUCCESS',
    LOG_OUT_FAIL: 'LOG_OUT_FAIL',

    GET_AUTH_INFO: 'GET_AUTH_INFO',
    GET_AUTH_INFO_SUCCESS: 'GET_AUTH_INFO_SUCCESS',
    GET_AUTH_INFO_FAIL: 'GET_AUTH_INFO_FAIL',

    GET_AUTH_MENU: 'GET_AUTH_MENU',
    GET_AUTH_MENU_SUCCESS: 'GET_AUTH_MENU_SUCCESS',
    GET_AUTH_MENU_FAIL: 'GET_AUTH_MENU_FAIL',

    SET_BREADCRUMB: 'SET_BREADCRUMB',
    
}
export default ActionsType