import ActionsType from './constants'

let initialState = {
    data: {},
    loading: false
};

const reducer = (state = initialState, action) => {
    const { type, payload} = action
    switch (type) {
        case ActionsType.GET_ASSOCIATE_COMPANY_ENTERPRISE:
            return Object.assign({}, state, {
                ...state, data: {}, loading: true
            });
        case ActionsType.GET_ASSOCIATE_COMPANY_SUCCESS_ENTERPRISE:
            return Object.assign({}, state, {
                ...state, data: payload, loading: false
            });
        case ActionsType.GET_ASSOCIATE_COMPANY_FAIL_ENTERPRISE:
            return Object.assign({}, state, {
                ...state, data: {}, loading: false
            });
        default:
            return state;
    }
};

export default reducer