import ActionsType from './constants'

let initialState = {
    lastReq: {},
    data: [],
    loading: false,
    total: 0,
    pageNo: 1,
    pageSize: 10,

    sendReqLoading: false,

    showDataExistTip: false,
    
    statusCode: -1,
    msg: '',
    baseInfoPreview: {}
};

const reducer = (state = initialState, action) => {
    const { type, payload} = action
    switch (type) {
        case ActionsType.GET_SEARCH_LIST:
            return Object.assign({}, state, {
                ...state, loading: true, data: [], total: 0, lastReq: {}
            });
        case ActionsType.GET_SEARCH_LIST_SUCCESS:
            return Object.assign({}, state, {
                ...state, 
                loading: false, 
                data: payload.results, 
                pageNo: payload.pageNo, 
                pageSize: payload.pageSize, 
                total: payload.totalRecord,
                lastReq: payload.lastReq
            });
        case ActionsType.GET_SEARCH_LIST_FAIL:
            return Object.assign({}, state, {
                ...state, loading: false, data: [], total: 0, lastReq: {}
            });

        case ActionsType.SEND_SEARCH_REQUEST:
            return Object.assign({}, state, {
                ...state, sendReqLoading: true
            });
        case ActionsType.SEND_SEARCH_REQUEST_SUCCESS:
            return Object.assign({}, state, {
                ...state, 
                sendReqLoading: false,
                statusCode: payload.statusCode, msg: payload.msg, baseInfoPreview: payload.baseInfo?.baseInfo
            });
        case ActionsType.SEND_SEARCH_REQUEST_FAIL:
            return Object.assign({}, state, {
                    ...state, sendReqLoading: false
            });

        case ActionsType.SHOW_DATA_EXIST_TIP:
            return Object.assign({}, state, {
                ...state, showDataExistTip: payload
            });

        case ActionsType.RESET_SEARCH_RESULT:
            return Object.assign({}, state, {
                ...state,
                statusCode: -1,
                msg: '',
                baseInfoPreview: {}
            });
        
        default:
            return state;
    }
};

export default reducer