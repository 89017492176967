import ActionsType from './constants'
//折叠/展开左侧菜单栏
export const Actions = {
    getRecordList(params) {
        return {type: ActionsType.GET_RECORD_LIST, payload: params}
    },
    getRecordListSuccess(result) {
        return {type: ActionsType.GET_RECORD_LIST_SUCCESS, payload: result}
    },
    getRecordListFail() {
        return {type: ActionsType.GET_RECORD_LIST_FAIL}
    }
}