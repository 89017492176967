import ActionsType from './constants'
export const Actions = {
    getRoleList(params) {
        return {type: ActionsType.GET_ROLE_LIST, payload: params}
    },
    getRoleListSuccess(result) {
        return {type: ActionsType.GET_ROLE_LIST_SUCCESS, payload: result}
    },
    getRoleListFail() {
        return {type: ActionsType.GET_ROLE_LIST_FAIL}
    },

    getRole(params, callback) {
        return {type: ActionsType.GET_ROLE, payload: params, callback: callback}
    },
    getRoleSuccess(result) {
        return {type: ActionsType.GET_ROLE_SUCCESS, payload: result}
    },
    getRoleFail() {
        return {type: ActionsType.GET_ROLE_FAIL}
    },

    addRole(params, callback) {
        return {type: ActionsType.ADD_ROLE, payload: params, callback}
    },
    addRoleSuccess(result) {
        return {type: ActionsType.ADD_ROLE_SUCCESS, payload: result}
    },
    addRoleFail() {
        return {type: ActionsType.ADD_ROLE_FAIL}
    },

    delRole(params, callback) {
        return {type: ActionsType.DEL_ROLE, payload: params, callback: callback}
    },
    delRoleSuccess(result) {
        return {type: ActionsType.DEL_ROLE_SUCCESS, payload: result}
    },
    delRoleFail() {
        return {type: ActionsType.DEL_ROLE_FAIL}
    },

    getRoleMenu(params, callback) {
        return {type: ActionsType.GET_ROLE_MENU, payload: params, callback: callback}
    },
    getRoleMenuSuccess(result) {
        return {type: ActionsType.GET_ROLE_MENU_SUCCESS, payload: result}
    },
    getRoleMenuFail() {
        return {type: ActionsType.GET_ROLE_MENU_FAIL}
    },

    getMenu(params) {
        return {type: ActionsType.GET_MENU, payload: params}
    },
    getMenuSuccess(result) {
        return {type: ActionsType.GET_MENU_SUCCESS, payload: result}
    },
    getMenuFail() {
        return {type: ActionsType.GET_MENU_FAIL}
    },
}