import { lazy } from 'react'
import enterprise from './enterprise'
import finance from './finance'
import system from './system'
import Login from '../page/login/index'
import H5 from '../page/h5/index'
import Home from '@/page/home'
import profile from './profile'
import snapshot from './snapshot'
import NotPermisson from '@/page/403'
import Notfound from '@/page/404'
import rate from './rate';

const rootRoutes = [
  {
    name: 'Login',
    exact: true,
    path: '/login',
    component: Login,
    needLogin: false
  },
  {
    name: 'H5',
    exact: true,
    path: '/h5',
    component: H5,
    needLogin: false
  },
  {
    name: '首页',
    exact: false,
    path: '/',
    component: lazy(() => import('@/layout')),
    needLogin: true,
    routes: [
      { name: '首页', exact: true, path: '/', render: () => (<Home />) },
      enterprise,
      finance,
      system,
      rate,
      // profile,
      // snapshot,
      {
        path: '/403',
        component: NotPermisson,
      },
      {
        path: '*',
        component: Notfound,
      }
    ]
  }
]

export default rootRoutes
