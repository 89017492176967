import {all} from 'redux-saga/effects'

let sagaList = []
const sagaContext = require.context('../page', true, /saga.js$/)
sagaContext.keys().forEach(key => {
  Object.keys(sagaContext(key)).forEach(child => {
    sagaList.push(...sagaContext(key)[child])
  })
})

export default function* rootSaga() {
  yield all(sagaList)
}
