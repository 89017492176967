import {combineReducers} from 'redux';
import login from '../page/login/reducer'
import record from '../page/profile/record/reducer'
import search from '../page/profile/search/reducer'
import info from '../page/profile/info/reducer'
import snapshot from '../page/snapshot/info/reducer'
import snapshotSearch from '../page/snapshot/search/reducer'
import snapshotRecord from '../page/snapshot/record/reducer'
import enterpriseRecord from '../page/enterprise/record/reducer'
import enterpriseSearch from '../page/enterprise/search/reducer'
import drawer from '../page/profile/info/content/relatedRisk/components/drawer/request/reducer'
import drawerEnterprise from '../page/enterprise/info/content/relatedRisk/components/drawer/request/reducer'
import bill from '../page/finance/bill/reducer'
import charge from '../page/finance/charge/reducer'
import account from '../page/system/account/reducer'
import user from '../page/system/user/reducer'
import role from '../page/system/role/reducer'
import h5 from '../page/h5/reducer'
const Reducer = combineReducers({
    login,
    record,
    search,
    info,
    snapshot,
    snapshotSearch,
    snapshotRecord,
    enterpriseRecord,
    enterpriseSearch,
    drawer,
    drawerEnterprise,
    bill,
    charge,
    account,
    user,
    role,
    h5,
});
export default Reducer;