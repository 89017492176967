import axios from 'axios'
import {message, notification} from 'antd';  

//创建axios实例
const httpService = axios.create({
  baseURL: '',
  responseType: 'json',
  timeout: 200000,
  withCredentials: true
})

httpService.interceptors.request.use(config => {
  return config;
}, err => {
  return Promise.reject(err)
})


httpService.interceptors.response.use(config => {
  return config;
}, err => {
  return Promise.reject(err)
})

// respone拦截器
httpService.interceptors.response.use(
  response => {
    const { data } = response
    if (data && data.retCode !== 1) {
      message.error(data.msg)
      return Promise.reject({ message: data.msg || '未定义错误' })
    }
    return response.data.result
  },
  error => {
    const { response } = error
    const msg = response.data.msg
    if (msg === 'Network Error') {
      notification.error({
        message: '请检测网络',
        description: msg
      })
      return Promise.reject(new Error(msg))
    }
    if (msg.indexOf('timeout') !== -1) {
      notification.error({
        message: '请求超时',
        description: msg
      })
      return Promise.reject(new Error(msg))
    }
    let statusText = ''
    switch (response.status) {
      case 401:
        message.warning('会话已过期，请重新登录！')
        sessionStorage.clear()
        return window.location.reload()
      case 403:
        statusText = '没有操作权限'
        break
      case 404:
        statusText = '访问出错'
        break
      case 500:
        statusText = '服务器出错'
        break
      default:
        statusText = '提示'
        break
    }
    // 全局提示
    notification.error({
      message: statusText,
      description: msg
    })
    return Promise.reject(error)
  }
)
export default function http (options) {
  return httpService(options)
    .then(res => {
      return res
    })
}