import ActionsType from './constants'
//折叠/展开左侧菜单栏
export const Actions = {
    login(params, callback) {
        return {type: ActionsType.LOG_IN, payload: params, callback: callback}
    },
    loginSuccess(result) {
        return {type: ActionsType.LOG_IN_SUCCESS, payload: result}
    },
    loginFail() {
        return {type: ActionsType.LOG_IN_FAIL}
    },
    logout(params, callback) {
        return {type: ActionsType.LOG_OUT, payload: params, callback: callback}
    },
    logoutSuccess(result) {
        return {type: ActionsType.LOG_OUT_SUCCESS, payload: result}
    },
    logoutFail() {
        return {type: ActionsType.LOG_OUT_FAIL}
    },
    getAuthInfo(params, callback) {
        return {type: ActionsType.GET_AUTH_INFO, payload: params, callback: callback}
    },
    getAuthInfoSuccess(result) {
        return {type: ActionsType.GET_AUTH_INFO_SUCCESS, payload: result}
    },
    getAuthInfoFail() {
        return {type: ActionsType.GET_AUTH_INFO_FAIL}
    },
    getAuthMenu(params, callback) {
        return {type: ActionsType.GET_AUTH_MENU, payload: params, callback: callback}
    },
    getAuthMenuSuccess(result) {
        return {type: ActionsType.GET_AUTH_MENU_SUCCESS, payload: result}
    },
    getAuthMenuFail() {
        return {type: ActionsType.GET_AUTH_MENU_FAIL}
    },
    setBreadcrumb(params) {
        return {type: ActionsType.SET_BREADCRUMB, payload: params}
    },
}