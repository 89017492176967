import ActionsType from './constants'
//折叠/展开左侧菜单栏
export const Actions = {
    getAccountInfo(params, callback) {
        return {type: ActionsType.GET_ACCOUNT_INFO, payload: params, callback: callback}
    },
    getAccountInfoSuccess(result) {
        return {type: ActionsType.GET_ACCOUNT_INFO_SUCCESS, payload: result}
    },
    getAccountInfoFail() {
        return {type: ActionsType.GET_ACCOUNT_INFO_FAIL}
    },

    editCompanyInfo(params, callback) {
        return {type: ActionsType.EDIT_COMPANY_INFO, payload: params, callback: callback}
    }
}