import ActionsType from './constants'
//折叠/展开左侧菜单栏
export const Actions = {
    getSnapshotRecordList(params) {
        return {type: ActionsType.GET_SNAPSHOT_RECORD_LIST, payload: params}
    },
    getSnapshotRecordListSuccess(result) {
        return {type: ActionsType.GET_SNAPSHOT_RECORD_LIST_SUCCESS, payload: result}
    },
    getSnapshotRecordListFail() {
        return {type: ActionsType.GET_SNAPSHOT_RECORD_LIST_FAIL}
    }
}