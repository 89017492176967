import ActionsType from './constants'
//折叠/展开左侧菜单栏
export const Actions = {
    getAssociateCompany(params) {
        return {type: ActionsType.GET_ASSOCIATE_COMPANY, payload: params}
    },
    getAssociateCompanySuccess(params) {
        return {type: ActionsType.GET_ASSOCIATE_COMPANY_SUCCESS, payload: params}
    },
    getAssociateCompanyFail(params) {
        return {type: ActionsType.GET_ASSOCIATE_COMPANY_FAIL, payload: params}
    }
}