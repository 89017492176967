import {lazy} from 'react'
import {Redirect} from 'react-router'
import PageLayout from '@/layout/pageLayout'

export default {
  name: '企业评分查询',
  exact: false,
  path: '/assess',
  component: PageLayout,
  needAuth: true,
  routes: [
    {
      name: '查询列表',
      exact: true,
      path: '/assess',
      needAuth: true,
      render:() => (<Redirect to={"/assess/list"}/>)
    },
    {
      name: '查询列表',
      exact: true,
      path: '/assess/list',
      needAuth: true,
      component: lazy(() => import('@/page/rate/list')),
    },
    {
      name: '审核结果',
      exact: true,
      path: '/assess/list/result/:recordId',
      needAuth: true,
      component: lazy(() => import('@/page/rate/list/result')),
    },
    {
      name: '评分报告',
      exact: true,
      path: '/assess/list/report/:recordId',
      needAuth: true,
      component: lazy(() => import('@/page/rate/list/report')),
    }
  ]
}
