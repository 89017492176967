import { lazy } from 'react'
import { Redirect } from 'react-router'
import PageLayout from '@/layout/pageLayout'

export default {
    name: '财务管理',
    exact: false, 
    path: '/finance', 
    component: PageLayout,
    needAuth: true,
    routes: [
        { name: '财务管理', exact: true, path: '/finance', needAuth: true, render:() => (<Redirect to={"/finance/charge"}/>) },
        { name: '充值记录', exact: true, path: '/finance/charge', needAuth: true, component: lazy(() => import('@/page/finance/charge')), keepAlive: true },
        { name: '月度账单', exact: true, path: '/finance/bill', needAuth: true, component: lazy(() => import('@/page/finance/bill')), keepAlive: true }
    ]
}