import { put, takeEvery, call } from 'redux-saga/effects'
import http from '../../../http'
import {Actions} from './action'
import ActionTypes from './constants'


function* getSnapshotData(action){
    try {
        const {payload, callback} = action
        let options = {
            url: '/web/record/getReport',
            method: 'GET',
            params: {
                ...payload
            }
        }
        let result = yield call(http, {...options})
        result.report.searchTime = result.searchTime
        yield put(Actions.getSnapShotSuccess(result.report))
        // 记录状态等于3，走完整画像的流程
        // if(payload.status !== 3) {
        //     yield put(SearchActions.sendSearchRequestSuccess({statusCode: result.statusCode, msg: result.msg, baseInfo: {baseInfo: result.report?.busiInfo?.baseInfo}}))
        // } else {
        //     yield put(Actions.getSnapShotSuccess(result.report))
        // }
        if(callback) {
            callback()
        }
        
    } catch(err) {
        yield put(Actions.getSnapShotFail())
    }
}

function* watch() {
    yield takeEvery(ActionTypes.GET_SNAPSHOT_DATA, getSnapshotData)
}

const Sagas = [
    watch()
]

export default Sagas