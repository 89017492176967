import { put, takeEvery} from 'redux-saga/effects'
import http from '@/http'
import {Actions} from './action'
import ActionTypes from './constants'

function* login(action){
    try {
        const { payload, callback } = action
        let param = new URLSearchParams();
        param.append('mobile', payload.mobile);
        param.append('password', payload.password);
        let result = yield http({
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            method: 'POST',
            url: '/web/admin/login',
            data: param
        })
        yield put(Actions.loginSuccess(result))
        sessionStorage.setItem('isLogin', true)
        if(callback) {
            callback()
        }
    } catch(err) {
        yield put(Actions.loginFail())
    }
}

function* logout(action){
    try {
        const { payload, callback } = action
        let result = yield http({
            method: 'POST',
            url: '/web/admin/logout',
            data: {...payload}
        })
        yield put(Actions.logoutSuccess(result))
        if(callback) {
            callback()
        }
        sessionStorage.clear()
    } catch(err) {
        yield put(Actions.logoutFail())
        sessionStorage.clear()
    }
}

function* getAuthInfo(action){
    try {
        const { payload, callback } = action
        let result = yield http({
            method: 'GET',
            url: '/web/staff/getAuthInfo',
            data: {...payload}
        })
        yield put(Actions.getAuthInfoSuccess(result))
        sessionStorage.setItem('authInfo', JSON.stringify(result))
        if(callback) {
            callback(result)
        }
    } catch(err) {
        yield put(Actions.getAuthInfoFail())
    }
}

function* getAuthMenu(action){
    try {
        const { payload, callback } = action
        let result = yield http({
            method: 'POST',
            url: '/web/role/getRoleOprate?roleId=' + payload
        })
        yield put(Actions.getAuthMenuSuccess(result))
        sessionStorage.setItem('authMenu', JSON.stringify(result))
        if(callback) {
            callback(result)
        }
    } catch(err) {
        yield put(Actions.getAuthMenuFail())
    }
}

function* watch() {
    yield takeEvery(ActionTypes.LOG_IN, login)
    yield takeEvery(ActionTypes.LOG_OUT, logout)
    yield takeEvery(ActionTypes.GET_AUTH_INFO, getAuthInfo)
    yield takeEvery(ActionTypes.GET_AUTH_MENU, getAuthMenu)
}

const Sagas = [
    watch()
]

export default Sagas