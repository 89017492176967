const Home = () => {
    const containerStyle = {
        width: '100%', 
        height: '100%', 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center',
        fontSize: '24px',
        color: '#bfbfbf'
    }
    return <div style={containerStyle}>
        产业数据风险管理平台
    </div>
}
export default Home