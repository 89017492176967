import {
    put,
    takeEvery
} from 'redux-saga/effects'
import http from '@/http'
import {
    Actions
} from './action'
import ActionTypes from './constants'

function* getAuthorizationLink(action) {
    try {
        const {
            payload,
        } = action
        let result = yield http({
            method: 'POST',
            url: `/web/jdApi/offLineAuth`,
            data: {
                ...payload
            }
        })
        yield put(Actions.getAuthorizationLinkSuccess(result))
    } catch (err) {
        yield put(Actions.getAuthorizationLinkFail())
    }
}



function* watch() {
    yield takeEvery(ActionTypes.GET_AUTHORIZATIONLINK, getAuthorizationLink)
    // yield takeEvery(ActionTypes.LOG_OUT, logout)
    // yield takeEvery(ActionTypes.GET_AUTH_INFO, getAuthInfo)
    // yield takeEvery(ActionTypes.GET_AUTH_MENU, getAuthMenu)
}

const Sagas = [
    watch()
]

export default Sagas