const ActionsType =  {
    // 查询画像记录
    GET_ENTERPRISE_RECORD_LIST: 'GET_ENTERPRISE_RECORD_LIST',
    GET_ENTERPRISE_RECORD_LIST_SUCCESS: 'GET_ENTERPRISE_RECORD_LIST_SUCCESS',
    GET_ENTERPRISE_RECORD_LIST_FAIL: 'GET_ENTERPRISE_RECORD_LIST_FAIL',
    // 重新获取授权链接
    GET_ENTERPRISE_RECORD_NEWLINK: 'GET_ENTERPRISE_RECORD_NEWLINK',
    GET_ENTERPRISE_RECORD_NEWLINK_SUCCESS: 'GET_ENTERPRISE_RECORD_NEWLINK_SUCCESS',
    GET_ENTERPRISE_RECORD_NEWLINK_FAIL: 'GET_ENTERPRISE_RECORD_NEWLINK_FAIL'
}
export default ActionsType