const ActionsType =  {
    GET_SEARCH_LIST: 'GET_SEARCH_LIST',
    GET_SEARCH_LIST_SUCCESS: 'GET_SEARCH_LIST_SUCCESS',
    GET_SEARCH_LIST_FAIL: 'GET_SEARCH_LIST_FAIL',

    SEND_SEARCH_REQUEST: 'SEND_SEARCH_REQUEST',
    SEND_SEARCH_REQUEST_SUCCESS: 'SEND_SEARCH_REQUEST_SUCCESS',
    SEND_SEARCH_REQUEST_FAIL: 'SEND_SEARCH_REQUEST_FAIL',

    SHOW_DATA_EXIST_TIP:  'SHOW_DATA_EXIST_TIP',

    RESET_SEARCH_RESULT: 'RESET_SEARCH_RESULT'
}
export default ActionsType