const ActionsType =  {
    GET_PROFILE_DATA: 'GET_PROFILE_DATA',
    GET_PROFILE_DATA_SUCCESS: 'GET_PROFILE_DATA_SUCCESS',
    GET_PROFILE_DATA_FAIL: 'GET_PROFILE_DATA_FAIL',

    GET_REPORT: 'GET_REPORT',
    GET_REPORT_SUCCESS: 'GET_REPORT_SUCCESS',
    GET_REPORT_FAIL: 'GET_REPORT_FAIL',

    SHOW_TIP_MODAL: 'SHOW_TIP_MODAL'
}
export default ActionsType