const ActionsType =  {
    GET_USER_LIST: 'GET_USER_LIST',
    GET_USER_LIST_SUCCESS: 'GET_USER_LIST_SUCCESS',
    GET_USER_LIST_FAIL: 'GET_USER_LIST_FAIL',
    
    GET_USER_ONE: 'GET_USER_ONE',
    GET_USER_ONE_SUCCESS: 'GET_USER_ONE_SUCCESS',
    GET_USER_ONE_FAIL: 'GET_USER_ONE_FAIL',

    ADD_USER: 'ADD_USER',
    ADD_USER_SUCCESS: 'ADD_USER_SUCCESS',
    ADD_USER_FAIL: 'ADD_USER_FAIL',

    EDIT_USER: 'EDIT_USER',
    EDIT_USER_SUCCESS: 'EDIT_USER_SUCCESS',
    EDIT_USER_FAIL: 'EDIT_USER_FAIL',

    CHANGE_USER_STATUS: 'CHANGE_USER_STATUS',
    CHANGE_USER_STATUS_SUCCESS: 'CHANGE_USER_STATUS_SUCCESS',
    CHANGE_USER_STATUS_FAIL: 'CHANGE_USER_STATUS_FAIL',

    DELETE_USER: 'DELETE_USER',
    DELETE_USER_SUCCESS: 'DELETE_USER_SUCCESS',
    DELETE_USER_FAIL: 'DELETE_USER_FAIL',
}
export default ActionsType