import ActionsType from './constants'
//折叠/展开左侧菜单栏
export const Actions = {
    getBill(params) {
        return {type: ActionsType.GET_BILL, payload: params}
    },
    getBillSuccess(result) {
        return {type: ActionsType.GET_BILL_SUCCESS, payload: result}
    },
    getBillFail() {
        return {type: ActionsType.GET_BILL_FAIL}
    }
}