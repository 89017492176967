import { put, takeEvery, call, select } from 'redux-saga/effects'
import http from '../../../http'
import {Actions} from './action'
import ActionTypes from './constants'
import {message, Modal} from 'antd'
import NoDataPic from '@/assets/img/search-no-data.png'
import SuccessPic from '@/assets/img/search-successful.png'
import BalancePic from '@/assets/img/insufficient-balance.png'
import HistoryPic from '@/assets/img/history-record.png'
import GenerationPic from '@/assets/img/data-generation.png'

import { setSearchHistory, getSearchHistory } from './utils'

function* getSnapshotSearchList(action){
    try {
        const { payload } = action
        let options = {
            url: '/web/record/userPageRecord',
            params: payload ? {...payload, type: 1, status: 3} : {pageSize: 10, pageNo: 1, type: 1, status: 3}
        }
        let result = yield call(http, {...options})
        result.lastReq = payload
        yield put(Actions.getSnapshotSearchListSuccess(result))
    } catch(err) {
        yield put(Actions.getSnapshotSearchListFail())
    }
}

function* getSnapshotJudge(action){
    try {
        const {payload, callback} = action
        let options = {
            url: '/web/portrait/judge',
            method: 'GET',
            params: {
                ...payload,
                searchType: 1
            }
        }
        let result = yield call(http, {...options})
        if(result !== null) {
            sessionStorage.setItem('snapshot_storage', JSON.stringify(result))
            yield put(Actions.getSnapshotJudgeSuccess(result))
            yield put(Actions.showDataExistTip(true))
        } else {
            yield put(Actions.getSnapshotJudgeSuccess(null))
            yield put(Actions.sendSnapshotSearchRequest({...payload}, callback))
        }
    } catch(err) {
        yield put(Actions.getSnapshotJudgeFail())
    }
}

function* sendSnapshotSearchReq(action){
    try {
        const {payload} = action
        let options = {
            url: '/web/portrait/search',
            method: 'POST',
            data: {
                ...payload,
                searchType: 1
            }
        }
        let result = yield call(http, {...options})
        yield put(Actions.sendSnapshotSearchRequestSuccess(result))
        // 设置搜索的历史记录
        if(result && [0, 1, 2, 3, 4, 5].includes(result.statusCode)) {
            let account = yield select(state => state.login.authInfo)
            let history = getSearchHistory(account.id) ? getSearchHistory(account.id).split(',') : []
            history.push(payload.searchName)
            history = Array.from(new Set([...history]))
            if(history.length > 10) {
                history = history.slice(0, 10)
            }
            setSearchHistory(account ? account.id : '', history.join(','))
        }
        // statusCode:
        // 0: 申请成功
        // 1: 未授权
        // 2: 余额不足
        // 3: 生成中
        // 4: 已生成
        // 5: 接口有波动
        // 6: 无企业数据
        // 7: 请勿频繁操作
        // 数据状态码为以下时, 才执行回调,回调是跳转到画像页面
        if(result.statusCode === 0) {
            Modal.success({
                width: 800,
                icon: null,
                content: <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <img style={{width: '270px', height: '190px'}} src={SuccessPic} alt="搜索成功" />
                    <div style={{marginTop: '25px', color: '#666666', fontSize: '18px'}}>搜索成功</div>
                    <div style={{marginTop: '15px', color: '#999999', fontSize: '14px'}}>{result.msg}</div>
                </div>,
                footer: null
            })
        }
        if(result.statusCode === 2) {
            Modal.info({
                width: 800,
                icon: null,
                content: <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <img style={{width: '270px', height: '190px'}} src={BalancePic} alt="账户余额不足" />
                    <div style={{marginTop: '25px', color: '#666666', fontSize: '18px'}}>账户余额不足</div>
                    <div style={{marginTop: '15px', color: '#999999', fontSize: '14px'}}>{result.msg}</div>
                </div>,
                footer: null
            })
        } 
        if(result.statusCode === 3) {
            Modal.info({
                width: 800,
                icon: null,
                content: <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <img style={{width: '270px', height: '190px'}} src={GenerationPic} alt="数据生成中" />
                    <div style={{marginTop: '25px', color: '#666666', fontSize: '18px'}}>数据生成中</div>
                    <div style={{marginTop: '15px', color: '#999999', fontSize: '14px'}}>{result.msg}</div>
                </div>,
                footer: null
            })
        }
        if(result.statusCode === 4) {
            Modal.info({
                width: 800,
                icon: null,
                content: <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <img style={{width: '270px', height: '190px'}} src={HistoryPic} alt="已生成" />
                    <div style={{marginTop: '25px', color: '#666666', fontSize: '18px'}}>已生成</div>
                    <div style={{marginTop: '15px', color: '#999999', fontSize: '14px'}}>{result.msg}</div>
                </div>,
                footer: null
            })
        } 
        if(result.statusCode === 6) {
            Modal.info({
                width: 800,
                icon: null,
                content: <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <img style={{width: '270px', height: '190px'}} src={NoDataPic} alt="找不到查询企业或暂无该企业数据" />
                    <div style={{marginTop: '25px', color: '#666666', fontSize: '18px'}}>找不到查询企业或暂无该企业数据</div>
                    <div style={{marginTop: '15px', color: '#999999', fontSize: '14px'}}>请重新核实查询企业名称，如有需要请联系平台客服。</div>
                </div>,
                footer: null
            })
        } 
        if(result.statusCode === 1 || result.statusCode === 5 || result.statusCode === 7) {
            message.warn(result.msg)
        }
    } catch(err) {
        yield put(Actions.sendSnapshotSearchRequestFail())
    }
}


function* watch() {
    yield takeEvery(ActionTypes.GET_SNAPSHOT_JUDGE, getSnapshotJudge)
    yield takeEvery(ActionTypes.GET_SNAPSHOT_SEARCH_LIST, getSnapshotSearchList)
    yield takeEvery(ActionTypes.SEND_SNAPSHOT_SEARCH_REQUEST, sendSnapshotSearchReq)
}

const Sagas = [
    watch()
]

export default Sagas