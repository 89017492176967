import ActionsType from './constants'
//折叠/展开左侧菜单栏
export const Actions = {
    getSearchList(params) {
        return {type: ActionsType.GET_SEARCH_LIST, payload: params}
    },
    getSearchListSuccess(result) {
        return {type: ActionsType.GET_SEARCH_LIST_SUCCESS, payload: result}
    },
    getSearchListFail() {
        return {type: ActionsType.GET_SEARCH_LIST_FAIL}
    },

    sendSearchRequest(params, callback) {
        return {type: ActionsType.SEND_SEARCH_REQUEST, payload: params, callback: callback}
    },
    sendSearchRequestSuccess(result) {
        return {type: ActionsType.SEND_SEARCH_REQUEST_SUCCESS, payload: result}
    },
    sendSearchRequestFail() {
        return {type: ActionsType.SEND_SEARCH_REQUEST_FAIL}
    },

    showDataExistTip(payload) {
        return{type: ActionsType.SHOW_DATA_EXIST_TIP, payload: payload}
    },

    resetSearchResult() {
        return{type: ActionsType.RESET_SEARCH_RESULT}
    }
}